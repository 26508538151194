enum FBAnalyticsEventTitles {
  // Login
  LOGIN = 'LOGIN',
  LOGIN_WITH_MICROSOFT = 'LOGIN_WITH_MICROSOFT',

  // Sign Up
  SIGN_UP = 'SIGN_UP',

  // Create new password
  CREATE_NEW_PASSWORD = 'CREATE_NEW_PASSWORD',

  // Forgot Password
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',

  // Audit
  AUDIT_CATEGORY_FILTER = 'AUDIT_CATEGORY_FILTER',
  AUDIT_DATE_RANGE_FILTER = 'AUDIT_DATE_RANGE_FILTER',
  AUDIT_RELATIVE_DATE_FILTER = 'AUDIT_RELATIVE_DATE_FILTER',

  // Device List
  DEVICE_LIST_REGISTER = 'DEVICE_LIST_REGISTER',
  DEVICE_LIST_EDIT = 'DEVICE_LIST_EDIT',
  DEVICE_LIST_DECOMISSION = 'DEVICE_LIST_DECOMISSION',
  DEVICE_LIST_SEE_DETAILS = 'DEVICE_LIST_SEE_DETAILS',
  DEVICE_LIST_ADD_TAG = 'DEVICE_LIST_ADD_TAG',
  DEVICE_LIST_DATE_RANGE_FILTER = 'DEVICE_LIST_DATE_RANGE_FILTER',
  DEVICE_LIST_RELATIVE_DATE_FILTER = 'DEVICE_LIST_RELATIVE_DATE_FILTER',
  DEVICE_LIST_SEARCH = 'DEVICE_LIST_SEARCH',

  // Event List
  EVENT_LIST_DATE_RANGE_FILTER = 'EVENT_LIST_DATE_RANGE_FILTER',
  EVENT_LIST_RELATIVE_DATE_FILTER = 'EVENT_LIST_RELATIVE_DATE_FILTER',
  EVENT_LIST_DEVICE_ID_FILTER = 'EVENT_LIST_DEVICE_ID_FILTER',

  // Device Groups
  DEVICE_GROUP_CREATE = 'DEVICE_GROUP_CREATE',
  DEVICE_GROUP_SEARCH = 'DEVICE_GROUP_SEARCH',
  DEVICE_GROUP_ADD_DEVICE_TO_GROUP = 'DEVICE_GROUP_ADD_DEVICE_TO_GROUP',
  DEVICE_GROUP_EDIT = 'DEVICE_GROUP_EDIT',
  DEVICE_GROUP_DELETE = 'DEVICE_GROUP_DELETE',

  // Impersonate User
  IMPERSONATE_USER = 'IMPERSONATE_USER',
  IMPERSONATE_USER_LEAVE = 'IMPERSONATE_USER_LEAVE',

  // Reports
  REPORT_LIST_SEARCH = 'REPORT_LIST_SEARCH',
  REPORT_LIST_CREATE = 'REPORT_LIST_CREATE',
  REPORT_LIST_DELETE = 'REPORT_LIST_DELETE',

  // Group Users
  GROUP_USERS_SEARCH = 'GROUP_USERS_SEARCH',
  GROUP_USERS_CREATE = 'GROUP_USERS_CREATE',
  GROUP_USERS_DELETE = 'GROUP_USERS_DELETE',
  GROUP_USERS_EDIT = 'GROUP_USERS_EDIT',

  // Login Security
  LOGIN_SECURITY_GENERATE_CODE = 'LOGIN_SECURITY_GENERATE_CODE',
  LOGIN_SECURITY_UPDATE_PASSWORD = 'LOGIN_SECURITY_UPDATE_PASSWORD',

  // My Account
  MY_ACCOUNT_UPDATE = 'MY_ACCOUNT_UPDATE',

  // Overview
  OVERVIEW_REDIRECT_TO_MY_ACCOUNT = 'OVERVIEW_REDIRECT_TO_MY_ACCOUNT',
  OVERVIEW_REDIRECT_TO_LOGIN_SECURITY = 'OVERVIEW_REDIRECT_TO_LOGIN_SECURITY',

  // Users
  USERS_CREATE_INVITE = 'USERS_CREATE_INVITE',
  USERS_SEARCH = 'USERS_SEARCH',
  USERS_ACTIVATE = 'USERS_ACTIVATE',
  USERS_DEACTIVATE = 'USERS_DEACTIVATE',
  USERS_DELETE_INVITE = 'USERS_DELETE_INVITE',
  USERS_UPDATE_ROLE = 'USERS_UPDATE_ROLE',

  // Tags
  TAGS_SEARCH = 'TAGS_SEARCH',
  TAGS_CREATE = 'TAGS_CREATE',
  TAGS_UPDATE = 'TAGS_UPDATE',
  TAGS_DELETE = 'TAGS_DELETE',
  TAGS_MANAGE_CREATE_VALUE = 'TAGS_MANAGE_CREATE_VALUE',
  TAGS_MANAGE_DELETE_VALUE = 'TAGS_MANAGE_DELETE_VALUE',
  TAGS_MANAGE_UPDATE_VALUE = 'TAGS_MANAGE_EDIT_VALUE',
  TAGS_ASSIGN_TO_DEVICES = 'TAGS_ASSIGN_TO_DEVICES',
}

export { FBAnalyticsEventTitles };
