import { GLSettingsMyAccount } from '@group-link-one/grouplink-components';

import { PageAnimated } from '../../../utils/pageAnimated';
import { useMyAccount } from './useMyAccount';

export const MyAccount = () => {
  const { user, handleUpdateAccountInformation } = useMyAccount();

  return (
    <PageAnimated>
      <GLSettingsMyAccount
        onSubmitAction={handleUpdateAccountInformation}
        user={user!}
      />
    </PageAnimated>
  );
};
