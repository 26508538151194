import { useAuth } from '@group-link-one/gl-utils';
import { useNavigate } from 'react-router-dom';
import { env } from '../env';

interface CallbackToModalLogout {
  callbackFnToModalLogout: () => void;
}

export function useCallbackToModalLogout(): CallbackToModalLogout {
  const navigate = useNavigate();
  const { clear } = useAuth();

  function callbackFnToModalLogout(): void {
    localStorage.removeItem(`@track-easy-${env.VITE_NODE_ENV}`);

    navigate('/login');
    localStorage.removeItem('isImpersonated');

    clear();
  }

  return {
    callbackFnToModalLogout,
  };
}
