import { Marker, Polygon } from '@react-google-maps/api';
import { motion } from 'framer-motion';

import { useMarker } from './useMarker';

export function MarkerMap() {
  const {
    markerPosition,
    randomPoints,
    markerIcon,
    sectorCoordinates,
    sectorCoordinatesMinDistance,
    onMarkerClick,
    onRandomPointClick,
  } = useMarker();

  return (
    <>
      <Marker
        position={markerPosition}
        animation={google.maps.Animation.DROP}
        icon={markerIcon}
        onClick={() => onMarkerClick()}
      />

      {randomPoints.map((point, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, scale: 0.5 }} // Estado inicial (invisível e pequeno)
          animate={{ opacity: 1, scale: 1 }} // Estado final (totalmente visível e no tamanho normal)
          transition={{ duration: 10 }} // Defina a duração da animação
        >
          <Marker
            key={index}
            position={{ lat: point.lat, lng: point.lng }}
            icon={{
              // Marker for the other devices in the radius area
              url:
                'data:image/svg+xml;utf-8,' +
                encodeURIComponent(`
      <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
        <!-- Raio maior com linha tracejada e fundo verde claro -->
        <circle cx="40" cy="40" r="40"  fill="#FC3F72FF" />
        <!-- Círculo interno centralizado -->
      </svg>
      `),
              scaledSize: new window.google.maps.Size(10, 10),
            }}
            onClick={() => onRandomPointClick(point)}
          />
        </motion.div>
      ))}
      <Polygon
        paths={sectorCoordinatesMinDistance}
        options={{
          fillColor: '#00de93',
          strokeColor: '#00FFAA',
          strokeWeight: 1,
          strokeOpacity: 0.5,
          fillOpacity: 0.3,
          strokePosition: google.maps.StrokePosition.OUTSIDE,
        }}
      />
      <Polygon
        paths={sectorCoordinates}
        options={{
          fillColor: '#00FFAA',
          strokeColor: '#00FFAA',
          strokeWeight: 2,
        }}
      />
    </> // Fragment
  );
}
