import { Marker } from '@react-google-maps/api';

import { useMarker } from './useMarker';

export function MarkerMap() {
  const { deviceSelected, markerIcon, onMarkerClick } = useMarker();

  return (
    <Marker
      position={{
        lat: Number(deviceSelected?.locLatitude),
        lng: Number(deviceSelected?.locLongitude),
      }}
      icon={markerIcon}
      animation={google.maps.Animation.DROP}
      onClick={() => onMarkerClick()}
    />
  );
}
