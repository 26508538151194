import { GL_COLORS, GLBox } from '@group-link-one/grouplink-components';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const TrackEasyMapHeader = styled(GLBox)`
  padding: 30px 40px;
`;

export const TrackEasyMapFooter = styled(GLBox)`
  /* padding: 30px 40px; */

  position: absolute;
  right: 40px;
  left: 30px;
  bottom: 40px;
`;

export const TrackEasyModal = styled(motion.div)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */

  width: 380px;
  height: 100%;

  padding: 20px;

  background-color: ${GL_COLORS.BACKGROUND_PRIMARY};
  border: 1px solid ${GL_COLORS.BORDER_COLOR};

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${GL_COLORS.BACKGROUND_SECONDARY};
  }

  &::-webkit-scrollbar-thumb {
    background: ${GL_COLORS.BORDER_COLOR};
  }
`;
