import 'react-day-picker/style.css';

import { AuthProvider, SentryProvider } from '@group-link-one/gl-utils';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import packageJson from '../package.json';
import App from './App.jsx';
import { initializeProducts } from './Context/FBProvider.jsx';
import { env } from './env.js';
import { queryClient } from './Services/queryClient.js';
import { SmartRetailTheme } from '@group-link-one/grouplink-components';

initializeProducts();

createRoot(document.getElementById('root')!).render(
  <SentryProvider
    config={{
      environment: env.VITE_SENTRY_ENVIRONMENT,
      dsn: env.VITE_SENTRY_DSN,
      appVersion: packageJson.version,
    }}
  >
    <QueryClientProvider client={queryClient}>
      <AuthProvider
        baseURL={env.VITE_REACT_APP_API_URL}
        keyStorage={`@track-easy-${env.VITE_NODE_ENV}`}
      >
        <BrowserRouter>
          <SmartRetailTheme />
          <App />
        </BrowserRouter>

        <ReactQueryDevtools />
      </AuthProvider>
    </QueryClientProvider>
  </SentryProvider>,
);
