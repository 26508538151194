import { FirebaseApp, initializeApp } from 'firebase/app';
import { env } from '../env';
import { initializeAnalytics } from './FBAnalytics/FBAnalyticsProvider';
import { initializeRemoteConfig } from './FBRemoteConfig/FBRemoteConfigProvider';
import { initializePerformance } from './FBPerformance/FBPerformanceProvider';

const firebaseConfig = JSON.parse(env.VITE_REACT_APP_FB_CONFIG_JSON);

const FBApp: FirebaseApp = initializeApp(firebaseConfig);

function initializeProducts(): void {
  initializeAnalytics(FBApp);
  initializePerformance(FBApp);
  initializeRemoteConfig(FBApp);
}

export { initializeProducts };
