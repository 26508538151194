import { ThemeChanger } from '@group-link-one/grouplink-components';
import { JSX, useEffect } from 'react';

import { AuthRoutes } from './auth.routes';
import { AuthenticatedRoutes } from './authenticated.routes';

export const Routes = (): JSX.Element => {
  useEffect(() => {
    ThemeChanger({
      light: '#F6F8F9',
      dark: '#313337',
    });
  }, []);

  return (
    <>
      <AuthRoutes />
      <AuthenticatedRoutes />
    </>
  );
};
