import { useAuth } from '@group-link-one/gl-utils';
import {
  GLHeader,
  GLHeaderNavListItemProps,
} from '@group-link-one/grouplink-components';
import { Outlet } from 'react-router-dom';

import { useAuthServices } from '../Services/authService/useAuthServices';
import { useMenuOptions } from './useMenuOptions';
import { JSX } from 'react';

interface PageLayoutProps {
  headerNavListItems?: GLHeaderNavListItemProps[];
  style?: React.CSSProperties;
}

export const PageLayout = ({
  headerNavListItems,
  style,
}: PageLayoutProps): JSX.Element => {
  const { user } = useAuth();
  const { logOut } = useAuthServices();
  const { menuOptions } = useMenuOptions();

  const headerNavItemActive = headerNavListItems?.find(
    (navItem) => navItem.path === window.location.pathname,
  );

  return (
    <>
      <GLHeader
        headerNavListItems={headerNavListItems}
        headerNavItemActive={headerNavItemActive?.id}
        user={user!}
        menuOptions={menuOptions}
        style={style}
        onLogOut={() => logOut({})}
      />
      <Outlet />
    </>
  );
};
