import {
  GL_COLORS,
  GL_EQUALIZER,
  GL_USER_CHECK,
  IMenuOptions,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useNavigate } from 'react-router-dom';

import { SettingsRoutes } from './usePageLayout';

export const useMenuOptions = (): { menuOptions: IMenuOptions[] } => {
  const { t } = useI18n();
  const navigate = useNavigate();

  const menuOptions: IMenuOptions[] = [
    {
      icon: <GL_USER_CHECK size={18} fill={GL_COLORS.FONT_COLOR} />,
      text: t('topbar.userModal.profile'),
      onClick: () => navigate(SettingsRoutes.OVERVIEW),
    },
    {
      icon: <GL_EQUALIZER size={18} fill={GL_COLORS.FONT_COLOR} />,
      text: t('topbar.userModal.accountSettings'),
      onClick: () => navigate(SettingsRoutes.MY_ACCOUNT),
    },
  ];

  return {
    menuOptions,
  };
};
