import { useAuth } from '@group-link-one/gl-utils';
import { GLHelpCenter } from '@group-link-one/grouplink-components';
import { Outlet, useNavigate } from 'react-router-dom';

import { JSX } from 'react';

export const HelpCenterLayout = (): JSX.Element => {
  const { accessTokenIsValid } = useAuth();
  const navigate = useNavigate();

  return (
    <GLHelpCenter
      image={''}
      userIsLogged={accessTokenIsValid}
      onHeaderButtonClick={() => navigate('/')}
    >
      <Outlet />
    </GLHelpCenter>
  );
};
