import { useAuth } from '@group-link-one/gl-utils';
import { useGLModalLogout } from '@group-link-one/grouplink-components';
import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { env } from '../env';
import { onRequestError } from '../Errors/onError';

const useAxiosHTTPRequest = () => {
  const navigate = useNavigate();
  const { accessToken, accessTokenIsValid } = useAuth();
  const { handleOpenModalLogout } = useGLModalLogout();

  const api = axios.create({
    baseURL: env.VITE_REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const apiPrivate = axios.create({
    baseURL: env.VITE_REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
      'X-Timestamp': new Date().getTime(),
    },
  });

  useEffect(() => {
    apiPrivate.interceptors.request.use((config) => {
      if (!accessTokenIsValid) {
        const source = axios.CancelToken.source();
        config.cancelToken = source.token;
        source.cancel('Access token is invalid');
      }

      config.headers.Authorization = accessToken;
      return config;
    });

    apiPrivate.interceptors.response.use(
      async (response) => {
        // await new Promise((resolve) => {
        //   resolve(true);
        // });

        return response;
      },
      (error) => {
        onRequestError(error);

        if (
          error.response?.status === 401 &&
          !error.response?.data?.message?.includes('privilégios')
        ) {
          handleOpenModalLogout();
          return;
        }

        if (error.response?.data?.message?.includes('privilégios')) {
          navigate('/');
          return;
        }

        return Promise.reject(error);
      },
    );
  }, [accessToken, apiPrivate, handleOpenModalLogout]);

  return {
    http: api,
    httpPrivate: apiPrivate,
  };
};

export default useAxiosHTTPRequest;
