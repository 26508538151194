import { useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useEffect, useState } from 'react';

import { useUserEventsStore } from './store/user-events-map-store';
import { env } from '@/env';
import { useThemeActiveStore } from '@/store/theme';
import { mapDarkStyles, mapStyles } from '@/utils/mapStyles';

const center = {
  lat: -23.5932056,
  lng: -46.6780125,
};

export const useTrackEasyMap = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const { state: themeActiveState, actions: themeActiveActions } =
    useThemeActiveStore();

  const [mapStyle, setMapStyle] = useState(
    themeActiveState.isDarkMode ? mapDarkStyles : mapStyles,
  );
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [markerIcon, setMarkerIcon] = useState<google.maps.Icon | undefined>(
    undefined,
  );
  const [userLocation] = useState<{ lat: number; lng: number } | null>(null);

  const { state: userEventsState } = useUserEventsStore();

  const onLoad = useCallback(
    function callback(mapInstance: google.maps.Map) {
      setMap(mapInstance);

      const svgIcon = {
        url:
          'data:image/svg+xml;utf-8,' +
          encodeURIComponent(`
        <svg width="36" height="36" xmlns="http://www.w3.org/2000/svg">
          <circle cx="18" cy="18" r="18" fill="#00FFAA" stroke="white" stroke-width="2"/>
        </svg>
      `),
        scaledSize: new window.google.maps.Size(22, 22),
      };

      setMarkerIcon(svgIcon);
    },
    [center],
  );

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map) {
      const zoomTimer = setTimeout(() => {
        map.setZoom(10);
      }, 400);
      return () => clearTimeout(zoomTimer);
    }
  }, [map]);

  useEffect(() => {
    setMapStyle(themeActiveState.isDarkMode ? mapDarkStyles : mapStyles);
  }, [themeActiveState.isDarkMode]);

  useEffect(() => {
    themeActiveActions.listenDarkMode();
  }, [themeActiveActions]);

  useEffect(() => {
    if (
      userEventsState.pointSelected &&
      map &&
      userEventsState.pointModalIsOpen
    ) {
      if (
        !userEventsState.pointSelected.event_info.loc_lat ||
        !userEventsState.pointSelected.event_info.loc_lon
      )
        return;

      const latLng = new window.google.maps.LatLng(
        Number(userEventsState.pointSelected.event_info.loc_lat),
        Number(userEventsState.pointSelected.event_info.loc_lon),
      );
      map.panTo(latLng);

      const currentZoom = map.getZoom();

      const zoomTimer = setTimeout(() => {
        if (currentZoom && currentZoom >= 18) return;

        map.setZoom(18);
      }, 600);
      return () => clearTimeout(zoomTimer);
    }
  }, [userEventsState.pointSelected, userEventsState.pointModalIsOpen, map]);

  useEffect(() => {
    if (map && userLocation) {
      const latLng = new google.maps.LatLng(userLocation.lat, userLocation.lng);
      map.panTo(latLng);
    }
  }, [map, userLocation]);

  return {
    center,
    map,
    deviceSelected: userEventsState.deviceSelected,
    markerIcon,
    mapStyle,
    userEventsState,
    isLoaded,
    onLoad,
    onUnmount,
  };
};
