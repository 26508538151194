import { GLFallbackPage, useI18n } from '@group-link-one/grouplink-components';
import Logo404 from '../../images/art_404.svg';
import RightLogo404 from '../../images/bg_404.svg';
import GLLogoDark from '../../images/Common/logo-glutilities-dark.svg';
import GLLogo from '../../images/Common/logo-glutilities-light.svg';
import { useNavigate } from 'react-router-dom';

export const MaintancePage = () => {
  const { t } = useI18n();
  const navigate = useNavigate();

  return (
    <GLFallbackPage
      logoOptions={{
        src: {
          logoLight: GLLogo,
          logoDark: GLLogoDark,
        },
        backgroundImage: RightLogo404,
        alt: 'GroupLink logo',
        logoRight: Logo404,
      }}
      onClickButton={() => navigate('/')}
      title={t('fallBack.maintanance.title')}
      subtitle={t('fallBack.maintanance.subtitle')}
      description={t('fallBack.maintanance.description')}
      hasButton={false}
    />
  );
};
