import {
  GL_COLORS,
  GL_DELETE,
  GL_PIN,
  GLBox,
  GLButton,
  GLButtonIcon,
  GLTypography,
  SkeletonBox,
  StyledAnim,
} from '@group-link-one/grouplink-components';
import { useEffect } from 'react';
import { format } from 'date-fns';

import { TrackEasyModal } from './PointModalStyle';
import { usePointModal } from './usePointModal';

export function PointModal() {
  const {
    events,
    state,
    trackEasyState,
    trackEasyActions,
    onClose,
    address,
    reverseGeocode,
    t,
  } = usePointModal();

  useEffect(() => {
    if (trackEasyState.pointSelected) {
      reverseGeocode({
        lat: Number(trackEasyState.pointSelected.event_info.loc_lat || 0),
        lng: Number(trackEasyState.pointSelected.event_info.loc_lon || 0),
      });
    }
  }, [address, trackEasyState.pointSelected]);

  return (
    <TrackEasyModal
      animate={{
        opacity: trackEasyState.pointModalIsOpen ? 1 : 0,
        x: trackEasyState.pointModalIsOpen ? 0 : '100%',
        pointerEvents: trackEasyState.pointModalIsOpen ? 'all' : 'none',
        animation: ['easeInOut'],
        overflowY: 'auto',
        transition: {
          duration: 0.2,
        },
      }}
    >
      <GLBox
        height="100%"
        align="center"
        direction="column"
        style={{ position: 'relative' }}
      >
        {!trackEasyState.isFetchingDeviceInfo ? (
          <>
            {trackEasyState.pointSelected ? (
              <>
                <GLButtonIcon
                  variant="outline"
                  icon={<GL_DELETE />}
                  color={GL_COLORS.FONT_COLOR}
                  style={{
                    position: 'absolute',
                    top: -10,
                    right: -10,
                  }}
                  onClick={onClose}
                />

                <GLBox
                  width={110}
                  height={110}
                  justify="center"
                  align="center"
                  borderRadius="50%"
                  style={{
                    backgroundColor: GL_COLORS.DANGER,
                    boxShadow: `0px 4px 4px ${GL_COLORS.SHADOW_COLOR}`,
                    marginBottom: 10,
                    flex: '0 0 auto',
                  }}
                >
                  <GL_PIN size={32} fill={GL_COLORS.WHITE} />
                </GLBox>

                <GLBox align="center" justify="center" gap={5}>
                  <GLTypography
                    text={t('userEvents.modal.userModal.user_id')}
                    weight={400}
                    fontSize={4}
                    color="FONT_COLOR"
                  />
                  <GLTypography
                    text={String(state.userId)}
                    weight={600}
                    fontSize={4}
                    color="FONT_COLOR"
                  />
                </GLBox>

                <GLBox
                  height={1}
                  width="100%"
                  style={{
                    backgroundColor: GL_COLORS.BORDER_COLOR,
                    margin: '10px 0',
                  }}
                />

                <GLBox direction="column" gap={20}>
                  <GLBox direction="column">
                    <GLTypography
                      text={t(
                        'eventList.modalMap.modalStreetView.eventSelected',
                      )}
                      weight={600}
                      as="p"
                      fontSize={5}
                      color="ACCENT_COLOR"
                    />

                    <GLBox direction="column">
                      <GLTypography
                        text={t('userEvents.modal.userModal.event_time')}
                        weight={600}
                        fontSize={4}
                        color="FONT_COLOR"
                      />

                      <GLTypography
                        text={
                          events?.event_info.event_time_millis
                            ? String(
                                format(
                                  events?.event_info.event_time_millis,
                                  'dd/MM/yyyy - HH:mm:ss',
                                ),
                              ) || ''
                            : '--'
                        }
                        weight={400}
                        fontSize={4}
                        color="FONT_COLOR"
                      />
                    </GLBox>

                    <GLBox direction="row" gap={5}>
                      <GLBox direction="column" gap={10}>
                        <GLTypography
                          text={t('userEvents.modal.userModal.loc_lat')}
                          weight={600}
                          fontSize={4}
                          color="FONT_COLOR"
                        />
                        <GLTypography
                          text={String(events?.event_info.loc_lat) || ''}
                          weight={400}
                          fontSize={4}
                          color="FONT_COLOR"
                        />
                      </GLBox>
                      <GLBox direction="column" gap={10}>
                        <GLTypography
                          text={t('userEvents.modal.userModal.loc_lon')}
                          weight={600}
                          fontSize={4}
                          color="FONT_COLOR"
                        />
                        <GLTypography
                          text={String(events?.event_info.loc_lon) || ''}
                          weight={400}
                          fontSize={4}
                          color="FONT_COLOR"
                        />
                      </GLBox>
                    </GLBox>
                  </GLBox>
                  {/*Dispositivos ao redor*/}
                  {events?.apps_data !== undefined &&
                    events?.apps_data?.length > 0 && (
                      <GLBox direction="column">
                        <GLTypography
                          text={t('userEvents.modal.userModal.devices_around')}
                          weight={600}
                          as="p"
                          fontSize={5}
                          color="ACCENT_COLOR"
                        />
                        {events?.apps_data?.map((event) => (
                          <GLBox key={event.adv_id} direction="column" gap={10}>
                            <GLBox direction="column">
                              <GLTypography
                                text={t('userEvents.modal.userModal.adv_id')}
                                weight={600}
                                fontSize={4}
                                color="FONT_COLOR"
                              />

                              <GLTypography
                                text={event.adv_id}
                                weight={400}
                                fontSize={4}
                                color="FONT_COLOR"
                              />
                            </GLBox>

                            <GLBox direction="row">
                              <GLBox direction="column">
                                <GLTypography
                                  text={t(
                                    'userEvents.modal.userModal.apps_installed',
                                  )}
                                  weight={600}
                                  fontSize={4}
                                  color="FONT_COLOR"
                                />

                                <GLTypography
                                  text={event.apps_installed}
                                  weight={400}
                                  fontSize={4}
                                  color="FONT_COLOR"
                                />
                              </GLBox>

                              <GLBox direction="column">
                                <GLTypography
                                  text={t('userEvents.modal.userModal.brand')}
                                  weight={600}
                                  fontSize={4}
                                  color="FONT_COLOR"
                                />

                                <GLTypography
                                  text={event.brand}
                                  weight={400}
                                  fontSize={4}
                                  color="FONT_COLOR"
                                />
                              </GLBox>
                            </GLBox>
                            <GLBox
                              height={1}
                              width="100%"
                              style={{
                                backgroundColor: GL_COLORS.BORDER_COLOR,
                                margin: '10px 0',
                              }}
                            />
                          </GLBox>
                        ))}
                      </GLBox>
                    )}
                </GLBox>
              </>
            ) : (
              <GLBox direction="column" height="100%" justify="center">
                <GLTypography
                  text={t(
                    'eventList.modalMap.modalStreetView.cardsSection.error',
                  )}
                  weight={600}
                  fontSize={6}
                  color="FONT_COLOR"
                  style={{ textAlign: 'center', marginBottom: 20 }}
                />

                <GLButton
                  align="center"
                  variant="fill"
                  text="Fechar"
                  weight={600}
                  onClick={() => {
                    trackEasyActions.setPointModalIsOpen(false);
                  }}
                />
              </GLBox>
            )}
          </>
        ) : (
          <GLBox direction="column" gap={20} align="center" height="100%">
            <SkeletonBox height={120} width={60}>
              <StyledAnim />
            </SkeletonBox>

            <SkeletonBox width={80} height={30}>
              <StyledAnim />
            </SkeletonBox>

            <SkeletonBox width={80} height={25}>
              <StyledAnim />
            </SkeletonBox>

            <GLBox
              height={1}
              width="100%"
              style={{
                backgroundColor: GL_COLORS.BORDER_COLOR,
                margin: '10px 0',
              }}
            />
            <GLBox direction="row" gap={25}>
              <SkeletonBox width={80} height={20}>
                <StyledAnim />
              </SkeletonBox>

              <SkeletonBox width={80} height={20}>
                <StyledAnim />
              </SkeletonBox>
            </GLBox>

            <GLBox style={{ marginTop: 'auto' }}>
              <SkeletonBox width={100} height={60}>
                <StyledAnim />
              </SkeletonBox>
            </GLBox>
          </GLBox>
        )}
      </GLBox>
    </TrackEasyModal>
  );
}
