import { UserCard } from './Events/UserEvents';
import { UserEventsCardsProps } from './UserEventsCards.types';
import { useUserEventsCard } from './useUserEventsCard';

export function UserEventsCards({ events }: UserEventsCardsProps) {
  const { onSeeMoreClick } = useUserEventsCard();

  return (
    <>
      <UserCard event={events} onSeeMoreClick={onSeeMoreClick} />
    </>
  );
}
