import { GLLogin } from '@group-link-one/grouplink-components';

import { useLogin } from './useLogin';

export const Login = () => {
  const { loginErrorMessage, redirectToForgotPassword, sendForm } = useLogin();

  return (
    <GLLogin
      loginErrorMessage={String(loginErrorMessage)}
      onRedirectToForgotPassword={redirectToForgotPassword}
      onSubmit={sendForm}
    />
  );
};
