import {
  GL_COLORS,
  GL_EYE,
  GLBox,
  GLButton,
  GLCardTemplate,
  GLTooltip,
  GLTypography,
} from '@group-link-one/grouplink-components';
import { format } from 'date-fns';

import { useEventListCard } from '../useEventListCard';
import { TrackCardProps } from './TrackCard.types';

export function TrackCard({ event, onSeeMoreClick }: TrackCardProps) {
  const { t, isMobile, features, formatTime } = useEventListCard();

  if (!event) return null;

  return (
    <GLCardTemplate.Provider>
      <GLCardTemplate.Root borderRadius="0px">
        {!isMobile ? (
          <GLCardTemplate.Header
            padding={features.show_event_details_btn ? '0px 20px' : undefined}
          >
            <GLCardTemplate.Column
              style={{
                borderRadius: '0px 10px',
              }}
              align="center"
              justify="flex-start"
            >
              <GLTypography
                text={String(
                  format(new Date(event.eventCreated), 'dd/MM/yyyy - HH:mm:ss'),
                )}
                color={'FONT_COLOR'}
              />
            </GLCardTemplate.Column>

            <GLCardTemplate.Column
              style={{
                borderRadius: '0px 10px',
              }}
              align="center"
              justify="flex-start"
            >
              <GLTypography
                text={String(
                  format(new Date(event.eventSent), 'dd/MM/yyyy - HH:mm:ss'),
                )}
                color={'FONT_COLOR'}
              />
            </GLCardTemplate.Column>

            <GLCardTemplate.Column
              style={{
                borderRadius: '0px 10px',
              }}
              align="center"
              justify="flex-start"
            >
              <GLTypography text={event.deviceId} color={'FONT_COLOR'} />
            </GLCardTemplate.Column>

            <GLCardTemplate.Column
              style={{
                borderRadius: '0px 10px',
              }}
              align="center"
              justify="flex-start"
            >
              <GLTypography
                text={Number(event.locLatitude).toFixed(5) || '--'}
                color={'FONT_COLOR'}
              />
            </GLCardTemplate.Column>

            <GLCardTemplate.Column
              style={{
                borderRadius: '0px 10px',
              }}
              align="center"
              justify="flex-start"
            >
              <GLTypography
                text={Number(event.locLongitude).toFixed(5) || '--'}
                color={'FONT_COLOR'}
              />
            </GLCardTemplate.Column>

            <GLCardTemplate.Column
              style={{
                borderRadius: '0px 10px',
              }}
              align="center"
              justify="flex-start"
            >
              <GLTypography
                text={String(
                  format(Number(event.locTime), 'dd/MM/yyyy - HH:mm:ss'),
                )}
                color={'FONT_COLOR'}
              />
            </GLCardTemplate.Column>
            {features.show_event_details_btn && (
              <GLCardTemplate.Column
                style={{
                  borderRadius: '0px 10px',
                }}
                align="center"
                justify="flex-start"
              >
                <GLButton
                  text={t('eventList.columns.seeMore')}
                  variant="outline"
                  align="center"
                  color={GL_COLORS.ACCENT_COLOR}
                  weight={600}
                  style={{
                    width: 'auto',
                  }}
                  icon={<GL_EYE />}
                  padding={false}
                  border={false}
                  onClick={() => onSeeMoreClick(event)}
                />
              </GLCardTemplate.Column>
            )}
          </GLCardTemplate.Header>
        ) : (
          <>
            <GLCardTemplate.Header direction="row">
              <GLCardTemplate.Column
                style={{
                  borderRadius: '0px 10px',
                }}
                align="flex-start"
                justify="flex-start"
                direction="column"
                gap={5}
              >
                <GLTypography
                  text={t('eventList.columns.device_id')}
                  color={'FONT_COLOR_DARK_GREY'}
                  fontSize={3}
                  weight={600}
                />
                <GLTypography
                  fontSize={3}
                  text={event.deviceId}
                  weight={600}
                  color={'FONT_COLOR'}
                />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column
                style={{
                  borderRadius: '0px 10px',
                }}
                align="flex-start"
                justify="flex-start"
                direction="column"
                gap={5}
              >
                <GLTypography
                  text={t('eventList.columns.created_at')}
                  color={'FONT_COLOR_DARK_GREY'}
                  fontSize={3}
                  weight={600}
                />
                <GLTypography
                  text={String(
                    format(event.eventCreated, 'dd/MM/yyyy - HH:mm:ss'),
                  )}
                  color={'FONT_COLOR'}
                  fontSize={3}
                />
              </GLCardTemplate.Column>

              <GLCardTemplate.Arrow />
            </GLCardTemplate.Header>

            <GLCardTemplate.Content>
              <GLBox
                direction="column"
                style={{
                  padding: 15,
                  backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
                  flexWrap: 'wrap',
                }}
                gap={20}
              >
                <GLBox>
                  <GLBox
                    style={{
                      borderRadius: '0px 10px',
                    }}
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                    gap={5}
                  >
                    <GLTypography
                      text={t('eventList.columns.sent')}
                      color={'FONT_COLOR_DARK_GREY'}
                      weight={600}
                      fontSize={3}
                    />
                    <GLTypography
                      text={String(
                        format(event.eventSent, 'dd/MM/yyyy - HH:mm:ss'),
                      )}
                      color={'FONT_COLOR'}
                      fontSize={3}
                    />
                  </GLBox>

                  <GLBox
                    style={{
                      borderRadius: '0px 10px',
                    }}
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                    gap={5}
                  >
                    <GLTypography
                      text={t('Loc accuracy')}
                      color={'FONT_COLOR_DARK_GREY'}
                      weight={600}
                      fontSize={3}
                    />

                    <GLTypography
                      text={event.locAccuracy ? event.locAccuracy : '--'}
                      color={'FONT_COLOR'}
                      fontSize={3}
                    />
                  </GLBox>

                  <GLBox
                    style={{
                      borderRadius: '0px 10px',
                    }}
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                    gap={5}
                  >
                    <GLTooltip position="top-left" text={event.locAltitude}>
                      <GLTypography
                        text={t('Loc altitude')}
                        color={'FONT_COLOR_DARK_GREY'}
                        weight={600}
                        fontSize={3}
                        style={{ marginBottom: 5 }}
                      />

                      <GLTypography
                        text={String(event.locAltitude || '--')}
                        color={'FONT_COLOR'}
                        fontSize={3}
                      />
                    </GLTooltip>
                  </GLBox>
                </GLBox>

                <GLBox>
                  <GLBox
                    style={{
                      borderRadius: '0px 10px',
                    }}
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                    gap={5}
                  >
                    <GLTypography
                      text="RSSI"
                      color={'FONT_COLOR_DARK_GREY'}
                      weight={600}
                      fontSize={3}
                    />
                    <GLTypography
                      text={String(event.rssi || '--')}
                      color={'FONT_COLOR'}
                      fontSize={3}
                    />
                  </GLBox>

                  <GLBox
                    style={{
                      borderRadius: '0px 10px',
                    }}
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                    gap={5}
                  >
                    <GLTypography
                      text={t('eventList.columns.duration')}
                      color={'FONT_COLOR_DARK_GREY'}
                      weight={600}
                      fontSize={3}
                    />
                    <GLTypography
                      text={formatTime(event.edgeTime)}
                      color={'FONT_COLOR'}
                      fontSize={3}
                    />
                  </GLBox>

                  <GLBox
                    style={{
                      borderRadius: '0px 10px',
                    }}
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                    gap={5}
                  >
                    <GLTooltip position="top-left" text={event.locDirection}>
                      <GLTypography
                        text={t('Loc direction')}
                        color={'FONT_COLOR_DARK_GREY'}
                        weight={600}
                        fontSize={3}
                        style={{ marginBottom: 5 }}
                      />

                      <GLTypography
                        text={String(event.locDirection || '--')}
                        color={'FONT_COLOR'}
                        fontSize={3}
                      />
                    </GLTooltip>
                  </GLBox>
                </GLBox>
                {features.show_event_details_btn && (
                  <GLCardTemplate.Column
                    style={{
                      borderRadius: '0px 10px',
                    }}
                    align="center"
                    justify="center"
                  >
                    <GLButton
                      text={t('eventList.columns.seeMore')}
                      variant="outline"
                      align="center"
                      color={GL_COLORS.ACCENT_COLOR}
                      weight={600}
                      style={{
                        width: 'auto',
                      }}
                      icon={<GL_EYE />}
                      padding={false}
                      border={false}
                    />
                  </GLCardTemplate.Column>
                )}
              </GLBox>
            </GLCardTemplate.Content>
          </>
        )}
      </GLCardTemplate.Root>
    </GLCardTemplate.Provider>
  );
}
