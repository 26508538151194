import {
  GL_COLORS,
  GL_DELETE,
  GL_PIN,
  GLBox,
  GLButton,
  GLButtonIcon,
  GLTypography,
  SkeletonBox,
  StyledAnim,
} from '@group-link-one/grouplink-components';
import { format } from 'date-fns';
import { useEffect } from 'react';

import { env } from '../../../env';
import StreetViewImage from '../StreetViewImage/StreetViewImage';
import { TrackEasyModal } from './DeviceModalStyle';
import { EventTrackCard } from './EventCardTrack/EventCardTrack';
import { useDeviceModal } from './useDeviceModal';

export function DeviceModal() {
  const {
    refBoxParentList,
    currentEventsCached,
    trackEasyState,
    trackEasyActions,
    onClose,
    address,
    reverseGeocode,
    t,
  } = useDeviceModal();

  useEffect(() => {
    if (trackEasyState.deviceSelected) {
      reverseGeocode({
        lat: Number(trackEasyState.deviceSelected.locLatitude || 0),
        lng: Number(trackEasyState.deviceSelected.locLongitude || 0),
      });
    }
  }, [address, trackEasyState.deviceSelected]);

  return (
    <TrackEasyModal
      animate={{
        opacity: trackEasyState.deviceModalIsOpen ? 1 : 0,
        x: trackEasyState.deviceModalIsOpen ? 0 : '100%',
        pointerEvents: trackEasyState.deviceModalIsOpen ? 'all' : 'none',
        animation: ['easeInOut'],
        overflowY: 'auto',
        transition: {
          duration: 0.2,
        },
      }}
    >
      <GLBox
        height="100%"
        align="center"
        direction="column"
        style={{ position: 'relative' }}
      >
        {!trackEasyState.isFetchingDeviceInfo ? (
          <>
            {trackEasyState.deviceSelected ? (
              <>
                <GLButtonIcon
                  variant="outline"
                  icon={<GL_DELETE />}
                  color={GL_COLORS.FONT_COLOR}
                  style={{
                    position: 'absolute',
                    top: -10,
                    right: -10,
                  }}
                  onClick={onClose}
                />

                <GLBox
                  width={110}
                  height={110}
                  justify="center"
                  align="center"
                  borderRadius="50%"
                  style={{
                    backgroundColor: GL_COLORS.DANGER,
                    boxShadow: `0px 4px 4px ${GL_COLORS.SHADOW_COLOR}`,
                    marginBottom: 10,
                    flex: '0 0 auto',
                  }}
                >
                  <GL_PIN size={32} fill={GL_COLORS.WHITE} />
                </GLBox>

                <GLBox align="center" justify="center" gap={5}>
                  <GLTypography
                    text={t('eventList.modalMap.modalStreetView.deviceId')}
                    weight={400}
                    fontSize={4}
                    color="FONT_COLOR"
                  />
                  <GLTypography
                    text={String(trackEasyState.deviceSelected?.deviceId)}
                    weight={600}
                    fontSize={4}
                    color="FONT_COLOR"
                  />
                </GLBox>

                <GLBox
                  height={1}
                  width="100%"
                  style={{
                    backgroundColor: GL_COLORS.BORDER_COLOR,
                    margin: '10px 0',
                  }}
                />

                <GLBox direction="column" gap={10}>
                  <GLTypography
                    text={t('eventList.modalMap.modalStreetView.eventSelected')}
                    weight={600}
                    as="p"
                    fontSize={5}
                    color="ACCENT_COLOR"
                  />
                  <GLBox direction="column" gap={5}>
                    <GLTypography
                      text={t(
                        'eventList.modalMap.modalStreetView.dateOccurrance',
                      )}
                      weight={600}
                      fontSize={4}
                      color="FONT_COLOR"
                    />
                    <GLTypography
                      text={`
                        ${format(trackEasyState.deviceSelected?.eventCreated || new Date(), 'dd/MM/yyyy HH:mm:ss')}
                        `}
                      weight={400}
                      fontSize={4}
                      color="FONT_COLOR"
                    />
                  </GLBox>

                  <GLBox direction="column" gap={5}>
                    <GLTypography
                      text={t(
                        'eventList.modalMap.modalStreetView.validatedOccurrence',
                      )}
                      weight={600}
                      fontSize={4}
                      color="FONT_COLOR"
                    />
                    <GLTypography
                      text={format(
                        trackEasyState.deviceSelected?.eventSent || new Date(),
                        'dd/MM/yyyy HH:mm:ss',
                      )}
                      weight={400}
                      fontSize={4}
                      color="FONT_COLOR"
                    />
                  </GLBox>
                  <GLBox direction="column" gap={5}>
                    <GLTypography
                      text={t(
                        'eventList.modalMap.modalStreetView.locationPrecision',
                      )}
                      weight={600}
                      fontSize={4}
                      color="FONT_COLOR"
                    />
                    <GLTypography
                      text={
                        `${trackEasyState.deviceSelected?.locAccuracy}m` || '--'
                      }
                      weight={400}
                      fontSize={4}
                      color="FONT_COLOR"
                    />
                  </GLBox>

                  <GLBox direction="column">
                    <GLBox direction="column" gap={5}>
                      <GLTypography
                        text={t('eventList.modalMap.modalStreetView.location')}
                        weight={600}
                        fontSize={4}
                        color="FONT_COLOR"
                      />
                      <GLTypography
                        text={address}
                        fontSize={4}
                        weight={400}
                        color={'ACCENT_COLOR'}
                        as="a"
                        target="_blank"
                        href={`https://www.google.com/maps?q=${trackEasyState.deviceSelected.locLatitude},${trackEasyState.deviceSelected.locLongitude}+(Trackeasy)&z=14&ll=${trackEasyState.deviceSelected.locLatitude},${trackEasyState.deviceSelected.locLongitude}`}
                      />
                    </GLBox>

                    <StreetViewImage
                      lat={Number(trackEasyState.deviceSelected!.locLatitude)}
                      lng={Number(trackEasyState.deviceSelected!.locLongitude)}
                      apiKey={env.REACT_APP_GOOGLE_MAPS_API_KEY}
                      width={280}
                      height={200}
                      style={{
                        borderRadius: 10,
                        border: `1px solid ${GL_COLORS.BORDER_COLOR}`,
                        overflow: 'hidden',
                      }}
                    />
                  </GLBox>
                </GLBox>

                <GLBox>
                  {/* <GLBox direction="column" gap={5}>
                    <GLTypography
                      text="Precisão em metros:"
                      weight={600}
                      fontSize={4}
                      color="FONT_COLOR"
                    />
                    <GLTypography
                      text={trackEasyState.deviceSelected.locAccuracy || ""}
                      weight={400}
                      fontSize={4}
                      color="FONT_COLOR"
                    />
                  </GLBox> */}

                  {/* <GLBox direction="column" gap={5}>
                    <GLTypography
                      text="Data da Localização:"
                      weight={600}
                      fontSize={4}
                      color="FONT_COLOR"
                    />
                    <GLTypography
                      text={format(trackEasyState.deviceSelected?.eventSent || new Date(), "dd/MM/yyyy HH:mm:ss")}
                      weight={400}
                      fontSize={4}
                      color="FONT_COLOR"
                    />
                  </GLBox> */}
                </GLBox>

                <GLBox
                  height={1}
                  width="100%"
                  style={{
                    backgroundColor: GL_COLORS.BORDER_COLOR,
                    margin: '10px 0',
                  }}
                />

                <GLBox
                  ref={refBoxParentList}
                  direction="column"
                  style={{ flex: 1 }}
                >
                  <GLTypography
                    text={t(
                      'eventList.modalMap.modalStreetView.cardsSection.lastEvents',
                    )}
                    weight={600}
                    as="p"
                    fontSize={5}
                    color="ACCENT_COLOR"
                  />

                  <GLBox direction="column">
                    {currentEventsCached &&
                      currentEventsCached
                        .slice(0, 3)
                        .map((event) => (
                          <EventTrackCard key={event.deviceId} event={event} />
                        ))}
                  </GLBox>
                </GLBox>
              </>
            ) : (
              <GLBox direction="column" height="100%" justify="center">
                <GLTypography
                  text={t(
                    'eventList.modalMap.modalStreetView.cardsSection.error',
                  )}
                  weight={600}
                  fontSize={6}
                  color="FONT_COLOR"
                  style={{ textAlign: 'center', marginBottom: 20 }}
                />

                <GLButton
                  align="center"
                  variant="fill"
                  text="Fechar"
                  weight={600}
                  onClick={() => {
                    trackEasyActions.setDeviceModalIsOpen(false);
                  }}
                />
              </GLBox>
            )}
          </>
        ) : (
          <GLBox direction="column" gap={20} align="center" height="100%">
            <SkeletonBox height={120} width={60}>
              <StyledAnim />
            </SkeletonBox>

            <SkeletonBox width={80} height={30}>
              <StyledAnim />
            </SkeletonBox>

            <SkeletonBox width={80} height={25}>
              <StyledAnim />
            </SkeletonBox>

            <GLBox
              height={1}
              width="100%"
              style={{
                backgroundColor: GL_COLORS.BORDER_COLOR,
                margin: '10px 0',
              }}
            />
            <GLBox direction="row" gap={25}>
              <SkeletonBox width={80} height={20}>
                <StyledAnim />
              </SkeletonBox>

              <SkeletonBox width={80} height={20}>
                <StyledAnim />
              </SkeletonBox>
            </GLBox>

            <GLBox style={{ marginTop: 'auto' }}>
              <SkeletonBox width={100} height={60}>
                <StyledAnim />
              </SkeletonBox>
            </GLBox>
          </GLBox>
        )}
      </GLBox>
    </TrackEasyModal>
  );
}
