import { useAuth } from '@group-link-one/gl-utils';
import { Privacy, Terms } from '@group-link-one/grouplink-components';
import { Navigate, Route, Routes } from 'react-router-dom';

import { NewPassword } from '../Pages/Onboard/CreateNewPassword/newPassword';
import { Forgot } from '../Pages/Onboard/Forgot/forgot';
import { Login } from '../Pages/Onboard/Login/login';
import { SignUp } from '../Pages/Onboard/SignUp/signUp';
import { VerifyRoute } from './VerifyRoute';
import { OnboardLayout } from '@/layouts/OnboardLayout';
import { HelpCenterLayout } from '@/layouts/HelpCenterLayout/HelpCenterLayout';
import { useFallbackPage } from '@/hooks/useFallbackpage';

export const AuthRoutes = () => {
  const { showPage } = useFallbackPage();
  const { accessToken, storeIsReady } = useAuth();
  const isToShowPageNotFound = !accessToken && storeIsReady;

  return (
    <Routes>
      <Route
        element={<VerifyRoute needAuth={false} element={<OnboardLayout />} />}
      >
        <Route
          path="forgot-password"
          element={<VerifyRoute needAuth={false} element={<Forgot />} />}
        />

        <Route
          path="/"
          element={<VerifyRoute needAuth={false} element={<Login />} />}
        />

        <Route
          path="/login"
          element={
            <VerifyRoute needAuth={false} element={<Navigate to="/" />} />
          }
        />
      </Route>

      {isToShowPageNotFound && (
        <>
          <Route element={<OnboardLayout />}>
            <Route path="/join-organization" element={<SignUp />} />
            <Route path="/new-password" element={<NewPassword />} />
          </Route>

          <Route element={<HelpCenterLayout />}>
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
          </Route>

          <Route path="*" element={showPage()} />
        </>
      )}
    </Routes>
  );
};
