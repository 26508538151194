import * as Sentry from '@sentry/react';
import * as yup from 'yup';

// Definição do esquema de validação
const envSchema = yup
  .object({
    VITE_NODE_ENV: yup.string().required(),
    VITE_SENTRY_DSN: yup.string().required(),
    VITE_SENTRY_ENVIRONMENT: yup.string().required(),
    VITE_REACT_APP_API_URL: yup.string().required(),
    VITE_REACT_APP_FB_CONFIG_JSON: yup.string().required(),
    VITE_REACT_APP_GOOGLE_MAPS_API_KEY: yup.string().required(),
    VITE_REACT_APP_FLAGS_ENV_KEY: yup.string().required(),
  })
  .required();

// Função de validação que também tipa o retorno
function validateEnv(env: NodeJS.ProcessEnv): yup.InferType<typeof envSchema> {
  try {
    const validatedEnv = envSchema.validateSync(env, { abortEarly: false });
    return validatedEnv as yup.InferType<typeof envSchema>;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
}

// Validação das variáveis de ambiente
export const env = validateEnv(import.meta.env);
