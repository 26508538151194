import { GLSettingsOverview } from '@group-link-one/grouplink-components';

import { PageAnimated } from '../../../utils/pageAnimated';
import { useOverview } from './useOverview';

export const Overview = () => {
  const { count, navigate, showFeature, user, isLoading } = useOverview();

  return (
    <PageAnimated>
      <GLSettingsOverview
        hasPrivileges={showFeature('show_settings_overview_apps_card')}
        isLoading={isLoading}
        user={user!}
        count={String(count)}
        editProfileButtonAction={() => navigate('/settings/my-account')}
        navigateToApps={() => navigate('/settings/apps')}
        navigateToLoginSecurity={() => navigate('/settings/login-security')}
      />
    </PageAnimated>
  );
};
