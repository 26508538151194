import {
  FlagsmithConfig,
  FlagsProvider,
  useAuth,
} from '@group-link-one/gl-utils';
import {
  GLMenuMobileProvider,
  GLModalLogoutProvider,
  GLToastProvider,
  I18n,
} from '@group-link-one/grouplink-components';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { JSX } from 'react';

import { FBAnalyticsProvider } from './Context/FBAnalytics/FBAnalyticsProvider';
import { env } from './env';
import { useCallbackToModalLogout } from './hooks/useCallbackToModalLogout';
import { Routes } from './Routes/index.routes';

const firebaseConfig = JSON.parse(env.VITE_REACT_APP_FB_CONFIG_JSON);

const FBApp: FirebaseApp = initializeApp(firebaseConfig);

// TODO: Add Flagsmith config
const flagConfig: FlagsmithConfig = {
  apiUrl: 'https://flagsmith.grouplinkone.com/api/v1/',
  environmentID: env.VITE_REACT_APP_FLAGS_ENV_KEY,
  cacheFlags: false,
  defaultFlags: {},
  enableLogs: false,
};

function App(): JSX.Element {
  const { callbackFnToModalLogout } = useCallbackToModalLogout();
  const { user } = useAuth();

  return (
    <FlagsProvider config={flagConfig} user={user}>
      <FBAnalyticsProvider FBApp={FBApp}>
        <GLModalLogoutProvider
          callbackFnToModalLogout={callbackFnToModalLogout}
        >
          <GLMenuMobileProvider>
            <GLToastProvider>
              <I18n>
                <Routes />
              </I18n>
            </GLToastProvider>
          </GLMenuMobileProvider>
        </GLModalLogoutProvider>
      </FBAnalyticsProvider>
    </FlagsProvider>
  );
}

export default App;
