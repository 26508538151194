import {
  GL_COLORS,
  GL_EYE,
  GLBox,
  GLButton,
  GLCardTemplate,
  GLTooltip,
  GLTypography,
} from '@group-link-one/grouplink-components';
import { format } from 'date-fns';

import { useUserEventsCard } from '../useUserEventsCard';
import { TrackCardProps } from './UserEvents.types';

export function UserCard({ event, onSeeMoreClick }: TrackCardProps) {
  const { t, isMobile, features, formatTime } = useUserEventsCard();

  if (!event) return null;

  return (
    <GLCardTemplate.Provider>
      <GLCardTemplate.Root borderRadius="0px">
        {!isMobile ? (
          <GLCardTemplate.Header
            padding={
              features.show_user_events_details_btn ? '0px 20px' : undefined
            }
          >
            <GLCardTemplate.Column
              style={{
                borderRadius: '0px 10px',
              }}
              align="center"
              justify="flex-start"
            >
              <GLTypography
                text={
                  event?.event_info?.event_time_millis
                    ? format(
                        event?.event_info?.event_time_millis,
                        'dd/MM/yyyy - HH:mm:ss',
                      )
                    : '--'
                }
                color={'FONT_COLOR'}
              />
            </GLCardTemplate.Column>

            <GLCardTemplate.Column
              style={{
                borderRadius: '0px 10px',
              }}
              align="center"
              justify="flex-start"
            >
              <GLTypography text={event?.adv_id || '--'} color={'FONT_COLOR'} />
            </GLCardTemplate.Column>

            {features.show_user_events_details_btn && (
              <GLCardTemplate.Column
                style={{
                  borderRadius: '0px 10px',
                }}
                align="center"
                justify="flex-start"
              >
                <GLButton
                  text={t('eventList.columns.seeMore')}
                  variant="outline"
                  align="center"
                  color={GL_COLORS.ACCENT_COLOR}
                  weight={600}
                  style={{
                    width: 'auto',
                  }}
                  icon={<GL_EYE />}
                  padding={false}
                  border={false}
                  onClick={() => onSeeMoreClick(event)}
                />
              </GLCardTemplate.Column>
            )}
          </GLCardTemplate.Header>
        ) : (
          <>
            <GLCardTemplate.Header direction="row">
              <GLCardTemplate.Column
                style={{
                  borderRadius: '0px 10px',
                }}
                align="flex-start"
                justify="flex-start"
                direction="column"
                gap={5}
              >
                <GLTypography
                  text={t('eventList.columns.device_id')}
                  color={'FONT_COLOR_DARK_GREY'}
                  fontSize={3}
                  weight={600}
                />
                <GLTypography
                  fontSize={3}
                  text={format(
                    event?.event_info.event_time_millis,
                    'dd/MM/yyyy - HH:mm:ss',
                  )}
                  weight={600}
                  color={'FONT_COLOR'}
                />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column
                style={{
                  borderRadius: '0px 10px',
                }}
                align="center"
                justify="flex-start"
              >
                <GLTypography text={event.adv_id} color={'FONT_COLOR'} />
              </GLCardTemplate.Column>

              <GLCardTemplate.Arrow />
            </GLCardTemplate.Header>

            <GLCardTemplate.Content>
              <GLBox
                direction="column"
                style={{
                  padding: 15,
                  backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
                  flexWrap: 'wrap',
                }}
                gap={20}
              >
                {features.show_user_events_details_btn && (
                  <GLCardTemplate.Column
                    style={{
                      borderRadius: '0px 10px',
                    }}
                    align="center"
                    justify="center"
                  >
                    <GLButton
                      text={t('eventList.columns.seeMore')}
                      variant="outline"
                      align="center"
                      color={GL_COLORS.ACCENT_COLOR}
                      weight={600}
                      style={{
                        width: 'auto',
                      }}
                      icon={<GL_EYE />}
                      padding={false}
                      border={false}
                    />
                  </GLCardTemplate.Column>
                )}
              </GLBox>
            </GLCardTemplate.Content>
          </>
        )}
      </GLCardTemplate.Root>
    </GLCardTemplate.Provider>
  );
}
