import { PaginateResponseProps } from '@group-link-one/grouplink-components';

import {
  Application,
  GetDevicesActivatedLastReadingsResponse,
} from '../deviceListService/useDeviceListService.types';
import useAxiosHTTPRequest from '../useAxiosHTTPRequest';
import {
  GetDevicesActivatedLastReadingsParams,
  GetEventListParams,
  GetEventListResponse,
} from './useEventListService.types';

export const useEventListService = () => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const getEventList = async ({
    from,
    until,
    device_id,
    ipp,
    next_page_token,
    application,
    user_id,
  }: GetEventListParams): Promise<
    PaginateResponseProps<GetEventListResponse[]>
  > => {
    const urlMap: Record<Application, string> = {
      GLUtilitiesWater: '/util-water/event-list',
      GLUtilitiesEnergy: '/util-kwh/event-list',
      GLSmartRetail: '/sr/event-list',
      GLTracking: '/tracking/event-list',
      GLUtilitiesLight: '/util-water/event-list',
    };

    return (
      await httpPrivate.get(urlMap[application], {
        params: {
          from,
          until,
          device_id,
          ipp,
          next_page_token,
          user_id,
        },
      })
    ).data;
  };

  const getDevices = async () => {
    return await httpPrivate.get('/sr/device-name');
  };

  const getDevicesActivatedLastReadings = async ({
    ...params
  }: GetDevicesActivatedLastReadingsParams): Promise<
    PaginateResponseProps<GetDevicesActivatedLastReadingsResponse[]>
  > => {
    const response = await httpPrivate.get('/devices-activated/last-reading', {
      params,
    });

    return response.data;
  };

  return {
    getEventList,
    getDevices,
    getDevicesActivatedLastReadings,
  };
};
