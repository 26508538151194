import { IForgotData, useI18n } from '@group-link-one/grouplink-components';
import { useToast } from '@group-link-one/grouplink-components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useForgotPasswordService } from '../../../Services/forgotPasswordService/useForgotServices';

export const useForgotPassword = () => {
  const { t } = useI18n();
  const { sendResetPasswordLink } = useForgotPasswordService();
  const { addToast } = useToast();
  const [disabled] = useState(false);
  const navigate = useNavigate();

  const sendForm = async ({ email }: IForgotData) => {
    await sendResetPasswordLink({
      email,
      link_config: 'smart',
    });

    addToast({
      message: t('forgot.toast.title'),
      title: t('forgot.toast.message'),
      type: 'success',
    });
  };

  const redirectToLogin = () => {
    navigate('/');
  };

  return {
    sendForm,
    redirectToLogin,
    disabled,
  };
};
