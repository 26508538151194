import {
  GL_BINOCULARS,
  GL_COLORS,
  GLEmptyList,
  GLTable,
} from '@group-link-one/grouplink-components';

import { usePointModal } from '../UserMap/PointModal/usePointModal';
import { UserEventsCards } from '../UserMap/PointModal/Cards/UserEventsCards';
import { useTrackColumns } from './Columns/Track';

export function UserEventsTableContent() {
  const { columns } = useTrackColumns();
  const { events, t } = usePointModal();

  return (
    <>
      {events ? (
        <GLTable
          columns={columns}
          content={<UserEventsCards events={events} />}
        />
      ) : (
        <GLEmptyList
          title={t('userEvents.table.emptyList.title')}
          description={t('userEvents.table.emptyList.description')}
          icon={
            <GL_BINOCULARS size={30} fill={GL_COLORS.FONT_COLOR_DARK_GREY} />
          }
          hasBorder
        />
      )}
    </>
  );
}
