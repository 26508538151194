import { useI18n } from '@group-link-one/grouplink-components';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useUserEventsStore } from '../store/user-events-map-store';
import { useUserEventsStore as GLUseUserEventsStore } from '@group-link-one/grouplink-components';
import { useUserEventServices } from '@/Services/userEventsService/useUserEventServices';
import { useQuery } from '@tanstack/react-query';
import { AppsData } from '@/Services/userEventsService/userEventServices.types';
import { DateTime } from 'luxon';
import { useDebounce } from 'use-debounce';

export const usePointModal = () => {
  const { t } = useI18n();
  const { getEvents } = useUserEventServices();
  const { state } = GLUseUserEventsStore();
  const [address, setAddress] = useState('');
  const [debouncedUserId] = useDebounce(state.userId, 1000);

  const search = useMemo(() => {
    if (!debouncedUserId) return 0;

    return Number(debouncedUserId);
  }, [debouncedUserId]);

  const { data: events } = useQuery({
    queryKey: ['get-events', search],
    queryFn: async () => {
      if (!search) return;

      const response = await getEvents({
        authToken:
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJVQyIsImV4cCI6MTc3Mjk5NzgwMH0.kteNipERC6rb5YtFhzdBZcMu8HuoSVim6FTPfjoOcT0',
        minutes: 50,
        mm: search,
      });

      return response.data;
    },
  });

  function formatTime(timestamp: number): string {
    const dt = DateTime.fromMillis(timestamp, { zone: 'utc' });

    if (dt.hour > 0) return dt.toFormat("HH'h 'mm'm'");

    if (dt.minute > 0) return dt.toFormat("mm'm 's's'");

    if (dt.second > 0) return dt.toFormat("s's");

    if (dt.millisecond > 0) return dt.toFormat('SSS') + 'ms';

    return '--';
  }

  const { state: trackEasyState, actions: trackEasyActions } =
    useUserEventsStore();

  function onClose() {
    trackEasyActions.setPointSelected(undefined);
    trackEasyActions.setIsEventListModalOpen(false);
  }

  const reverseGeocode = (locationParam: { lat: number; lng: number }) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ location: locationParam }, (results, status) => {
      if (status === 'OK' && results && results[0]) {
        setAddress(results[0].formatted_address);
      } else {
        console.error('Erro na geocodificação reversa:', status);
      }
    });
  };

  return {
    events,
    state,
    trackEasyState,
    trackEasyActions,
    onClose,
    address,
    reverseGeocode,
    formatTime,
    t,
  };
};
