import { AxiosError } from 'axios';

import { env } from '../env';

export function onRequestError(error: unknown) {
  if (error instanceof AxiosError) {
    if (env.NODE_ENV === 'development') {
      console.error(error);
    }
  }
}
