import { useAuth, useGLFlags } from '@group-link-one/gl-utils';
import {
  Changelogs,
  Privacy,
  Terms,
  useGLModalLogout,
} from '@group-link-one/grouplink-components';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import {
  LOCAL_STORAGE_KEY_EXTERNAL_TIME,
  useCheckTimeManipulation,
} from '@/hooks/useCheckTimeManipulation';

import { MainLayout } from '../layouts/MainLayout/MainLayout';
import { EventList } from '../Pages/EventList/eventList';
import { NewPassword } from '../Pages/Onboard/CreateNewPassword/newPassword';
import { SignUp } from '../Pages/Onboard/SignUp/signUp';
import { LoginSecurity } from '../Pages/Settings/LoginSecurity/loginSecurity';
import { MyAccount } from '../Pages/Settings/MyAccount/myAccount';
import { Overview } from '../Pages/Settings/Overview/overview';
import { VerifyRoute } from './VerifyRoute';
import { usePrivileges } from '@/hooks/usePrivilleges';
import { PageLayout } from '@/layouts/PageLayout';
import { SettingsRoutes, usePageLayout } from '@/layouts/usePageLayout';
import { OnboardLayout } from '@/layouts/OnboardLayout';
import { HelpCenterLayout } from '@/layouts/HelpCenterLayout/HelpCenterLayout';
import { UserEvents } from '@/Pages/UserEvents/userEvents';
import { useFallbackPage } from '@/hooks/useFallbackpage';

export const AuthenticatedRoutes = () => {
  const location = useLocation();
  const { flags } = useGLFlags();
  const { pages } = usePrivileges();
  const { getHeaderNavList } = usePageLayout();
  const { initializeTime } = useCheckTimeManipulation();
  const { accessToken, accessTokenIsValid, setActionOnIdle } = useAuth();
  const [changelogContent, setChangelogContent] = useState('');
  const { showPage } = useFallbackPage();

  const { handleOpenModalLogout, modalLogoutIsOpenOpen } = useGLModalLogout();

  const indexRoute = useMemo(() => {
    return SettingsRoutes.OVERVIEW;
  }, [flags]);

  const settingsNavList = getHeaderNavList('settings');

  useEffect(() => {
    fetch('changelog.md')
      .then((res) => res.text())
      .then(setChangelogContent);
  }, []);

  useEffect(() => {
    setActionOnIdle?.(handleOpenModalLogout);
  }, []);

  useEffect(() => {
    if (accessToken && !accessTokenIsValid && !modalLogoutIsOpenOpen) {
      handleOpenModalLogout();
    }
  }, [accessToken]);

  useEffect(() => {
    const hasKeyExternalTime = localStorage.getItem(
      LOCAL_STORAGE_KEY_EXTERNAL_TIME,
    );
    if (accessToken && !hasKeyExternalTime) {
      initializeTime();
    }
  }, [accessToken]);

  return (
    <AnimatePresence mode="wait" initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route
          element={
            <VerifyRoute
              needAuth={true}
              userHasPrivileges={true}
              element={<MainLayout />}
            />
          }
        >
          <Route
            path="settings"
            element={
              <VerifyRoute
                needAuth={true}
                element={<PageLayout headerNavListItems={settingsNavList} />}
                userHasPrivileges={Object.values(pages.settings).some(
                  (value) => value,
                )}
              />
            }
          >
            <Route
              index
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<Navigate to="overview" />}
                  userHasPrivileges={pages.settings.overview}
                />
              }
            />
            <Route
              path="overview"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<Overview />}
                  userHasPrivileges={pages.settings.overview}
                />
              }
            />

            <Route
              path="my-account"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<MyAccount />}
                  userHasPrivileges={pages.settings.myAccount}
                />
              }
            />

            <Route
              path="login-security"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<LoginSecurity />}
                  userHasPrivileges={pages.settings.loginSecurity}
                />
              }
            />
          </Route>

          <Route
            path="network"
            element={
              <VerifyRoute
                needAuth={true}
                element={<PageLayout />}
                userHasPrivileges={pages.eventList}
              />
            }
          >
            <Route
              path="event-list"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<EventList />}
                  userHasPrivileges={pages.eventList}
                />
              }
            />
          </Route>

          <Route
            path="user-events"
            element={
              <VerifyRoute
                needAuth={true}
                element={<PageLayout />}
                userHasPrivileges={pages.userEvents}
              />
            }
          >
            <Route
              path=""
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<UserEvents />}
                  userHasPrivileges={pages.userEvents}
                />
              }
            />
          </Route>

          <Route
            index
            path="/"
            element={
              <VerifyRoute
                needAuth={true}
                element={<Navigate to={indexRoute} />}
                userHasPrivileges={true}
              />
            }
          />
        </Route>

        {accessToken && (
          <>
            <Route element={<OnboardLayout />}>
              <Route path="/join-organization" element={<SignUp />} />
              <Route path="/new-password" element={<NewPassword />} />
            </Route>

            <Route element={<HelpCenterLayout />}>
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route
                path="/changelog"
                element={<Changelogs changelogContent={changelogContent} />}
              />
            </Route>

            <Route path="*" element={showPage()} />
          </>
        )}
      </Routes>
    </AnimatePresence>
  );
};
