import axios from 'axios';
import { UserEventParams, UserEventResponse } from './userEventServices.types';

export const useUserEventServices = () => {
  const getEvents = async ({
    authToken,
    minutes,
    mm,
  }: UserEventParams): Promise<UserEventResponse> => {
    return axios.get(
      `https://external-dataflow-api.apidatasafe.com/external/lookup-adv-id-events?authToken=${authToken}&minutes=${minutes}&mm=${mm}`,
    );
  };

  return {
    getEvents,
  };
};
