import { i18n } from '@group-link-one/grouplink-components';
import { subDays } from 'date-fns';
import { createWithEqualityFn } from 'zustand/traditional';

import { GetEventListResponse } from '../../../Services/eventListService/useEventListService.types';

export interface TrackEasyStoreState {
  eventListModalIsOpen: boolean;
  deviceModalIsOpen: boolean;
  deviceSelected: GetEventListResponse | undefined;
  isFetchingDeviceInfo: boolean;
}

export interface TrackEasyStoreActions {
  setDeviceModalIsOpen: (deviceModalIsOpen: boolean) => void;
  setDeviceSelected: (deviceSelected?: GetEventListResponse) => void;
  setIsEventListModalOpen: (eventListModalIsOpen: boolean) => void;
  setIsFetchingDeviceInfo: (isFetchingDeviceInfo: boolean) => void;
  resetAll: () => void;
}

export interface TrackEasyStore {
  state: TrackEasyStoreState;
  actions: TrackEasyStoreActions;
}

export const useTrackEasyStore = createWithEqualityFn<TrackEasyStore>(
  (set) => ({
    state: {
      deviceModalIsOpen: true,
      deviceSelected: undefined,
      eventListModalIsOpen: false,
      isFetchingDeviceInfo: false,
    },
    actions: {
      setDeviceModalIsOpen: (deviceModalIsOpen) =>
        set((state) => ({
          state: {
            ...state.state,
            deviceModalIsOpen,
          },
        })),

      setDeviceSelected: (deviceSelected) =>
        set((state) => ({
          state: {
            ...state.state,
            deviceSelected,
          },
        })),

      setIsFetchingDeviceInfo: (isFetchingDeviceInfo) =>
        set((state) => ({
          state: {
            ...state.state,
            isFetchingDeviceInfo,
          },
        })),

      setIsEventListModalOpen: (eventListModalIsOpen) =>
        set((state) => ({
          state: {
            ...state.state,
            eventListModalIsOpen,
          },
        })),

      resetAll: () =>
        set((state) => ({
          state: {
            ...state.state,
            deviceModalIsOpen: false,
            deviceSelected: undefined,
            devicesFetched: undefined,
            alertFilterValue: [{ id: 'all', text: 'Todos' }],
            alertTypesSelected: undefined,
            isFetchingDevices: false,
            currentDateRange: undefined,
            currentRelativeTime: {
              id: 'last_7_days',
              range: {
                from: subDays(new Date(), 7),
                to: new Date(),
              },
              text: i18n.t('days.last7Days'),
            },
            geo_filter: undefined,
            range: {
              from: subDays(new Date(), 7),
              to: new Date(),
            },
          },
        })),
    },
  }),
);
