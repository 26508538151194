import {
  IJoinUserOrganization,
  useI18n,
  useToast,
} from '@group-link-one/grouplink-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { useOrganizationUsersService } from '../../../Services/organizationUsersService/useOrganizationUsers';

export const useSignUp = () => {
  const { t } = useI18n();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { joinOrganization } = useOrganizationUsersService();
  const { addToast } = useToast();

  const extractTokenFromSearch = () => {
    const params = new URLSearchParams(search);
    return params.get('invitation-token');
  };

  const joinUser = async ({
    name,
    last_name,
    password,
  }: IJoinUserOrganization) => {
    try {
      const response = await joinOrganization({
        token: extractTokenFromSearch(),
        name,
        last_name,
        password,
      });

      addToast({
        title: t('signUp.toast.account_created.title'),
        message: t('signUp.toast.account_created.message'),
        type: 'success',
      });

      navigate('/login');

      return response.data;
    } catch (error: any) {
      if (error.response.data.code === 'invite_already_expired') {
        addToast({
          title: t('signUp.toast.expired_invite.title'),
          message: t('signUp.toast.expired_invite.message'),
          type: 'error',
        });
      }
    }
  };

  return {
    joinUser,
  };
};
