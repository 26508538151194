import { useGLFlags } from '@group-link-one/gl-utils';

type Pages = {
  reports: boolean;
  eventList: boolean;
  userEvents: boolean;
  settings: {
    overview: boolean;
    myAccount: boolean;
    loginSecurity: boolean;
  };
};

export type Features = {
  show_event_details_btn: boolean;
  show_user_events_details_btn: boolean;
};

interface UsePrivileges {
  pages: Pages;
  features: Features;
}

export const usePrivileges = (): UsePrivileges => {
  const { showFeature } = useGLFlags();

  const pages: Pages = {
    reports: showFeature('show_report_page'),
    eventList: showFeature('show_event_list_page'),
    userEvents: showFeature('show_user_events_page'),
    settings: {
      overview: showFeature('show_settings_overview'),
      myAccount: showFeature('show_settings_my_account'),
      loginSecurity: showFeature('show_settings_security'),
    },
  };

  const features: Features = {
    show_event_details_btn: showFeature('show_event_details_btn'),
    show_user_events_details_btn: showFeature('show_user_events_details_btn'),
  };

  return {
    pages,
    features,
  };
};
