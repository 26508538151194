import { MaintancePage } from '@/Pages/MaintenancePage/maintenance';
import {
  GLFallbackPage,
  GLLoadingPage,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useNavigate } from 'react-router-dom';
import GLLogoDark from '../images/Common/logo-glutilities-dark.svg';
import GLLogo from '../images/Common/logo-glutilities-light.svg';
import Logo404 from '../images/art_404.svg';
import RightLogo404 from '../images/bg_404.svg';
import { useGLFlags } from '@group-link-one/gl-utils';

export const useFallbackPage = () => {
  const { t } = useI18n();
  const { flagsAlreadyLoaded, isMaintenanceMode } = useGLFlags();
  const navigate = useNavigate();

  const showPage = () => {
    if (!flagsAlreadyLoaded) return <GLLoadingPage />;

    if (isMaintenanceMode) return <MaintancePage />;

    return (
      <GLFallbackPage
        logoOptions={{
          src: {
            logoLight: GLLogo,
            logoDark: GLLogoDark,
          },
          backgroundImage: RightLogo404,
          alt: 'GroupLink logo',
          logoRight: Logo404,
        }}
        onClickButton={() => navigate('/')}
        title={t('fallBack.notFound.title')}
        subtitle={t('fallBack.notFound.subtitle')}
        description={t('fallBack.notFound.description')}
        hasButton
      />
    );
  };

  return {
    showPage,
  };
};
