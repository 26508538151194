import { useAuth } from '@group-link-one/gl-utils';
import { IColumns } from '@group-link-one/grouplink-components';

import { useTrackColumns } from './Track';

export type UseCase = 'track';

export interface AllColumns {
  columnName: string;
  hasActions?: boolean;
  useCases: UseCase[];
}

export const useAllColumns = () => {
  const { user } = useAuth();

  const userUseCase = user?.use_case as UseCase;

  if (!userUseCase) return [];

  const { columns: trackColumns } = useTrackColumns();

  const columns: Record<UseCase, IColumns[]> = {
    track: trackColumns,
  };

  return columns[userUseCase];
};
