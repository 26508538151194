import { usePrivileges } from '@/hooks/usePrivilleges';
import { IColumns, useI18n } from '@group-link-one/grouplink-components';
import { useEffect, useMemo } from 'react';

export const useTrackColumns = () => {
  const { t } = useI18n();
  const { features } = usePrivileges();

  const columns: IColumns[] = useMemo(() => {
    const columns = [
      {
        columnName: t('eventList.columns.created_at'),
        hasActions: false,
      },
      {
        columnName: t('eventList.columns.sent'),
        hasActions: false,
      },
      {
        columnName: t('eventList.columns.device_id'),
      },
      {
        columnName: t('eventList.columns.locLatitude'),
        hasActions: false,
      },
      {
        columnName: t('eventList.columns.locLongitude'),
        hasActions: false,
      },
      {
        columnName: t('eventList.columns.locTime'),
        hasActions: false,
      },
    ];

    if (features.show_event_details_btn) {
      columns.push({
        columnName: t('eventList.columns.actions'),
        hasActions: false,
      });
    }

    return columns;
  }, [features.show_event_details_btn]);

  return {
    columns,
  };
};
