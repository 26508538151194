import { useMemo } from 'react';

import { useTrackEasyStore } from '../store/track-easy-map-store';

export const useMarker = () => {
  const { state: trackEasyState, actions: trackEasyActions } =
    useTrackEasyStore();

  const markers = {
    activeSelected: {
      url:
        'data:image/svg+xml;utf-8,' +
        encodeURIComponent(`
        <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
            <!-- Raio maior com linha tracejada e fundo verde claro -->
            <circle cx="50" cy="50" r="50" fill="#FC3F7270" stroke="#FC3F72" stroke-width="2" stroke-dasharray="4 2"/>
            <!-- Círculo interno centralizado -->
            <circle cx="50" cy="50" r="10" fill="#FC3F72" stroke="white" stroke-width="2"/>
        </svg>
      `),
      scaledSize: new window.google.maps.Size(96, 96),
    },
  };

  function getMarkerIcon() {
    return markers.activeSelected;
  }

  async function onMarkerClick() {}

  const markerIcon = useMemo(() => {
    return getMarkerIcon();
  }, [trackEasyState.deviceModalIsOpen, trackEasyState.deviceSelected]);

  const deviceSelected = trackEasyState.deviceSelected;

  return {
    markerIcon,
    deviceSelected,
    trackEasyState,
    trackEasyActions,
    onMarkerClick,
  };
};
