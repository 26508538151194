import {
  GL_COLORS,
  GLBox,
  GLCardTemplate,
  GLTypography,
} from '@group-link-one/grouplink-components';
import { format } from 'date-fns';
import { useEffect } from 'react';

import { env } from '../../../../env';
import { useEventListCard } from '../../../EventList/Cards/useEventListCard';
import StreetViewImage from '../../StreetViewImage/StreetViewImage';
import { EventTrackCardProps } from './EventCardTrack.types';

export function EventTrackCard({ event }: EventTrackCardProps) {
  const { address, reverseGeocode, t } = useEventListCard();

  useEffect(() => {
    reverseGeocode({
      lat: Number(event.locLatitude),
      lng: Number(event.locLongitude),
    });
  }, []);

  return (
    <GLCardTemplate.Provider>
      <GLCardTemplate.Root
        borderRadius="8px"
        style={{ width: '100%', minHeight: 75 }}
      >
        <GLCardTemplate.Header>
          <GLCardTemplate.Column
            align="flex-start"
            justify="flex-start"
            direction="column"
            gap={5}
          >
            <GLTypography
              text={format(event.eventCreated, 'dd/MM/yyyy HH:mm:ss')}
              fontSize={4}
              weight={600}
              color={'FONT_COLOR'}
              as="p"
            />

            <GLTypography
              // text={`${formatDateDistanceText(new Date(event.eventCreated).toString())}`}
              text={t(
                'eventList.modalMap.modalStreetView.cardsSection.card.moreDetails',
              )}
              fontSize={4}
              weight={400}
              color={'FONT_COLOR_DARK_GREY'}
              as="p"
            />
          </GLCardTemplate.Column>

          <GLCardTemplate.Arrow />
        </GLCardTemplate.Header>

        <GLCardTemplate.Content
          style={{
            padding: '15px 20px 15px 20px',
            backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
          }}
        >
          <GLBox direction="column">
            {/* <GLBox direction="column" gap={5}>
              <GLTypography
                text="Data e hora:"
                fontSize={3.5}
                weight={600}
                color={"FONT_COLOR"}
                as="p"
              />
              <GLTypography
                text={format(
                  event?.locTime || new Date().getUTCMilliseconds(),
                  "dd/MM/yyyy HH:mm:ss"
                )}
                fontSize={4}
                weight={400}
                color={"FONT_COLOR"}
                as="p"
              />
            </GLBox> */}

            <GLBox direction="column" gap={5}>
              <GLTypography
                text={t(
                  'eventList.modalMap.modalStreetView.cardsSection.card.location',
                )}
                fontSize={3.5}
                weight={600}
                color={'FONT_COLOR'}
                as="p"
              />
              <GLTypography
                text={address}
                fontSize={4}
                weight={400}
                color={'ACCENT_COLOR'}
                as="a"
                target="_blank"
                href={`https://www.google.com/maps?q=${event.locLatitude},${event.locLongitude}+(Trackeasy)&z=14&ll=${event.locLatitude},${event.locLongitude}`}
              />
            </GLBox>

            <StreetViewImage
              lat={Number(event.locLatitude)}
              lng={Number(event.locLongitude)}
              apiKey={env.REACT_APP_GOOGLE_MAPS_API_KEY}
              width={280}
              height={200}
              style={{
                borderRadius: 10,
                border: `1px solid ${GL_COLORS.BORDER_COLOR}`,
                overflow: 'hidden',
              }}
            />
          </GLBox>
        </GLCardTemplate.Content>
      </GLCardTemplate.Root>
    </GLCardTemplate.Provider>
  );
}
