import {
  GL_COLORS,
  GLBox,
  GLDetailedModal,
  useI18n,
} from '@group-link-one/grouplink-components';

import { UserEventsMap } from '../UserMap/UserEventsMap';
import { useUserEventsStore } from '../UserMap/store/user-events-map-store';

export function UserEventsModalMap() {
  const { t } = useI18n();
  const { state: userEventsState } = useUserEventsStore();

  return (
    <GLDetailedModal
      open={userEventsState.eventListModalIsOpen}
      title={t('userEvents.modal.title')}
      width="100%"
      heigth="100%"
      closeIconColor={GL_COLORS.DANGER}
      asFullScreen={true}
      style={{
        padding: 40,
        backgroundColor: GL_COLORS.CUSTOM_MODAL_BACKGROUND,
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
        opacity: 0,
      }}
      content={
        <GLBox width="100%" height="100%">
          <UserEventsMap />
        </GLBox>
      }
    />
  );
}
