import { useAuth } from '@group-link-one/gl-utils';
import { useGLFlags } from '@group-link-one/gl-utils';
import { useNavigate } from 'react-router-dom';

import { useApp } from '../Apps/useApp';

export const useOverview = () => {
  const { showFeature } = useGLFlags();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { count, isLoading } = useApp();

  return {
    user,
    count,
    isLoading,
    navigate,
    showFeature,
  };
};
