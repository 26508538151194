import { GLUserEvents } from '@group-link-one/grouplink-components';

import { UserEventsTableContent } from './Content/TableContent';
import { useRelativeTimeFilter } from './Content/useRelativeTimeFilter';
import { UserEventsModalMap } from './ModalMap/UserEventsModalMap';

export const UserEvents = () => {
  const { relativeFilterTimeOptions } = useRelativeTimeFilter();

  return (
    <>
      <GLUserEvents
        hasFilterByVictimId={false}
        tableContent={<UserEventsTableContent />}
        relativeFilterTimeOptions={relativeFilterTimeOptions}
        hasFilterByRelativeTime={false}
      />

      <UserEventsModalMap />
    </>
  );
};
