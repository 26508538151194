import { useAuth } from '@group-link-one/gl-utils';
import { useGLModalLogout } from '@group-link-one/grouplink-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

export const useAuthServices = () => {
  const { handleOpenModalLogout } = useGLModalLogout();
  const { logOut, accessToken } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  async function handleLogout(accessTokenParam?: string) {
    try {
      if (!accessTokenParam) return;
      await logOut(accessTokenParam);

      queryClient.clear();
      localStorage.removeItem('isImpersonated');
      navigate('/');
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          handleOpenModalLogout();
        }
      }
    }
  }

  const { mutateAsync: logoutFn } = useMutation<
    any,
    any,
    { session_id?: string }
  >({
    mutationFn: () => handleLogout(accessToken),
  });

  return {
    logOut: logoutFn,
  };
};
